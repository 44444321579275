.dashboard .ui.form .field :disabled {
  opacity: 1;
}

.dashboard .ui.disabled.input {
  opacity: 1;
}

.dashboard .six.wide.column.profile-pic {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.dashboard .no-skills {
  color: grey;
}

.dashboard .cover-photo-placeholder {
  background-color: lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
}
