.ui.search > .results {
  background-color: #037792;
  margin-top: 0;
  background-color: white !important;
  border: 2px solid #767676 !important;
  border-radius: 8px !important;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
}

.ui.search > .results .result:hover {
  background-color: rgba(4, 149, 183);
}

.ui.search > .results .result .title {
  @apply font-rubik sm:text-[18px] text-base sm:leading-[30px] leading-[24px] sm:tracking-[-0.69px] tracking-[-0.5px];
  color: black;
}

.ui.search > .results .result .title:hover {
  color: #000;
}

.ui.search > .results::before {
  content: "";
  background-color: white !important;
  position: block !important;
  margin-left: 0 !important;
  height: 100% !important;
  width: 100% !important;
  border-radius: 8px !important;
}
