.container {
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(220, 220, 220, 1);
  background: #ffffff;
}

.text {
  width: calc(100% - 135px);
}

.bar {
  width: 135px;
}

.title {
  font-family: Nexa bold;
}

.image {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
}

.image img {
  border-radius: 50%;
}

.percent {
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  font-family: Nexa light;
}

.complete {
  font-size: 12px;
  line-height: 14px;
  font-family: Nexa light;
}

.linkSeparator {
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
}
