/* Hide scrollbar for Chrome, Safari and Opera */
.noScrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.noScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.profile--image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
