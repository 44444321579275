.roundedBorder {
  border-radius: 70px;
  border: solid 2px transparent;
  padding: 0.5rem 1rem;
  margin-bottom: 0.75rem;
  border-radius: 80px;
  background: linear-gradient(white, white),
    linear-gradient(90deg, #37b6bf 0%, #e0a93e 101.93%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  box-sizing: border-box;
  padding: 10px 30px;
}

.roundyBoxyBorder {
  border: solid 2px transparent;
  padding: 0.5rem 1rem;
  margin-bottom: 0.75rem;
  border-radius: 25px;
  background: linear-gradient(white, white),
    linear-gradient(90deg, #37b6bf 0%, #e0a93e 101.93%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  box-sizing: border-box;
  padding: 10px 30px;
}
