@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loadingSpinner {
  @apply w-8 h-8;
  border: 4px solid #82cadb; /* Light grey */
  border-top: 4px solid #0495b7; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
