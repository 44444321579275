.profile--image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.arrow-container {
  width: 32px;
  height: 0px;
  flex-shrink: 0;
  stroke-width: 1px;
  stroke: #202021;
}
