.borderGradientVertical {
  border-right: 2px solid;
  border-image-source: linear-gradient(135deg, #f4d675 0%, #83d9bb 100%);
}

.borderGradientHorizontal {
  border-bottom: 2px solid;
  border-image-source: linear-gradient(135deg, #f4d675 0%, #83d9bb 100%);
}

.dividerGradientVertical {
  background-image: linear-gradient(135deg, #f4d675 0%, #83d9bb 100%);
  width: 2px;
}

.dividerGradientHorizontal {
  background-image: linear-gradient(135deg, #f4d675 0%, #83d9bb 100%);
  height: 2px;
}

.containerWithSidebar {
  display: grid;
  grid-template-columns: 2fr 2px 3fr;
  grid-template-rows: auto;
}

@media (max-width: 768px) {
  .containerWithSidebar {
    grid-template-columns: 1fr;
  }
}
