input[type="number"] {
  /* Hide the up and down arrows */
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
