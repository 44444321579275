.customer-dashboard-header {
  position: relative;
  background: url("images/bg-circles.png");
  background-repeat: no-repeat;
  background-position: center;
  background-position-x: right;
}

.customer-dashboard-header--image {
  width: 270px;
  height: 270px;

  border-radius: 50%;
  z-index: 1;
}

.customer-dashboard-header--image img {
  width: 100%;
  height: 100%;
}
