.fc .fc-toolbar .fc-next-button.fc-button,
.fc .fc-toolbar .fc-prev-button.fc-button {
  background-color: transparent;
  color: #000000;
  font-family: inherit;
  font-size: inherit;
  border-radius: 100%;
  box-shadow: none;
  border: none;
  margin-left: 1px;
}

.fc .fc-toolbar .fc-today-button.fc-button:not(:disabled):hover,
.fc .fc-toolbar .fc-prev-button.fc-button:hover,
.fc .fc-toolbar .fc-next-button.fc-button:hover {
  background-color: #f8f8f8;
  color: #0495b7;
  cursor: pointer;
}

.fc .fc-toolbar .fc-next-button.fc-button:focus,
.fc .fc-toolbar .fc-prev-button.fc-button:focus {
  color: #0495b7;
  cursor: pointer;
  background-color: #e9e9e9;
}

.fc .fc-toolbar .fc-next-button.fc-button:active,
.fc .fc-toolbar .fc-prev-button.fc-button:active {
  box-shadow: none;
}

.fc .fc-toolbar .fc-today-button.fc-button {
  background-color: transparent;
  color: #000000;
  font-family: inherit;
  font-size: inherit;
  font-weight: bold;
  box-shadow: none;
  border: 1px solid #e4e4e4;
  margin-left: 5px;
}

.fc .fc-toolbar .fc-today-button.fc-button:active {
  box-shadow: none;
}

.fc .fc-toolbar .fc-dayGridMonth-button.fc-button,
.fc .fc-toolbar .fc-timeGridWeek-button.fc-button,
.fc .fc-toolbar .fc-timeGridDay-button.fc-button {
  background-color: transparent;
  color: #000000;
  font-family: inherit;
  font-size: inherit;
  box-shadow: none;
  border-radius: 0px;
  font-weight: bold;
  border: none;
  border-bottom: 1px solid #c5c5c5;
}

.fc .fc-toolbar .fc-dayGridMonth-button.fc-button:hover,
.fc .fc-toolbar .fc-timeGridWeek-button.fc-button:hover,
.fc .fc-toolbar .fc-timeGridDay-button.fc-button:hover {
  color: #0495b7;
  cursor: pointer;
  border-bottom: 2px solid #0495b7;
}

.fc .fc-toolbar .fc-dayGridMonth-button.fc-button:focus,
.fc .fc-toolbar .fc-timeGridWeek-button.fc-button:focus,
.fc .fc-toolbar .fc-timeGridDay-button.fc-button:focus {
  color: #0495b7;
  cursor: pointer;
  border-bottom: 2px solid #0495b7;
  box-shadow: none;
}

.fc .fc-toolbar .fc-dayGridMonth-button.fc-button-active,
.fc .fc-toolbar .fc-timeGridWeek-button.fc-button-active,
.fc .fc-toolbar .fc-timeGridDay-button.fc-button-active {
  color: #0495b7;
  cursor: pointer;
  border-bottom: 2px solid #0495b7;
  box-shadow: none;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 2px;
}

.highlight-event {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5) !important;
}
